export const apiFailedResponse = (error) => {

  let response = {
    message: 'Something went wrong, Please Try Again Later',
    status: false,
    errors: [],
  };

  if (!error.response) {
    return response;
  }

  response.message = error.response.data?.message || error.message;
  response.errors = error.response.data?.errors || [];

  return Promise.reject(response);
};
