import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';
import { categories } from '@/api/api';

export default {
  namespaced: true,

  state: () => ({
    ...state
  }),

  getters: {
    ...getters,
  },

  mutations: {
    ...mutations
  },

  actions: {
    ...actions,
    async getCategoryData({ dispatch }) {

      const response = await categories();
      dispatch('setData', response.data.data);

    }
  }
};
