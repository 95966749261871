export default {
  setLoading: ({ commit }, payload) => commit('updateLoading', payload),
  setData: ({ commit }, payload) => commit('updateData', payload),
  setErrors: ({ commit }, payload) => commit('updateErrors', payload),
  setErrorMsg: ({ commit }, payload) => commit('updateErrorMsg', payload),


  /**
   * It maps request errors in store & also controls loading
   *
   * @param commit
   * @param handler : CallableFunction
   * @param errorHandler : CallableFunction
   * @param loadingMutation : String
   * @param errorMutation : String
   * @param errorMsgMutation : String
   * @return {Promise<*>}
   */
  handleAsyncAction: async ({ commit }, {
    handler = () => {},
    errorHandler = () => {},
    loadingMutation = 'updateLoading',
    errorMutation = 'updateErrorMsg',
    errorMsgMutation = 'updateErrors'
  }) => {

    // set loading state true
    commit(loadingMutation, true);

    try {

      // run actual action
      const result = await handler();

      // set loading state false
      commit(loadingMutation, false);

      return result;

    } catch (e) {

      // execute something when error occurs
      errorHandler(e);

      // set error data in store
      commit(errorMsgMutation, e.message);
      commit(errorMutation, e.errors);
    }

    // set loading state false
    commit(loadingMutation, false);
  }
};
