import middlewareHandler from './middlewareHandler';
import routeMap from '@/utils/routeMap';

const { validateUserIsGuest } = middlewareHandler();

async function guest(to, from, next) {

  const validated = await validateUserIsGuest();

  if(validated){
    return await next();
  }

  let redirectTo = to.query.redirect
      ? {path: to?.query?.redirect }
      : { name: routeMap.user.profile.name }
  ;

  return next(redirectTo);

}

export default guest;
