import { http } from '@/plugins/http';
import { PRODUCT_BY_SHOP, SHOP_CREATE_REQUEST_URL, SHOP_DATA, SINGLE_SHOP_URL } from '@/utils/api';

import { getApiResponse } from '@/utils/helper';

export const singleShop = async (slug) => getApiResponse(() => http.get(`${SINGLE_SHOP_URL}/${slug}`));

export const shopData = async (slug) => getApiResponse(() => http.get(SHOP_DATA(slug)));

export const shopProducts = async (slug, params = null) => {
  let url = params ? `${PRODUCT_BY_SHOP(slug)}?${params}` : PRODUCT_BY_SHOP(slug);
  return getApiResponse(() => http.get(url));
};

export const shopCreateRequest = async (data) => getApiResponse(() => http.post(SHOP_CREATE_REQUEST_URL, data));
