const colors = {
  primary: {
    lite: null,
    main: '#DC3C44',
    dark: '#A62223'
  },

  secondary: {
    lite: '#CAF0F9',
    main: '#56BDF0',
    dark: null
  },

  lite: '#F7FDFB',
  grey: '#D7D5D5',
  dark: '#0F1C2E',

  accents: {
    default: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },

  brands: {
    facebook: '#4267B2',
    messenger: '#006AFF',
    youtube: '#FF0000',
    skype: '#00AFF0',
    twitter: '#1DA1F2'
  }

};

export const lightTheme = {

  primary: colors.primary.main,
  primaryDark: colors.primary.dark,

  secondary: colors.secondary.main,
  secondaryLight: colors.secondary.lite,

  lite: colors.lite,
  grey: colors.grey,
  dark: colors.dark,

  anchor: colors.primary.dark,


  accent: colors.accents.default,
  error: colors.accents.error,
  info: colors.accents.info,
  success: colors.accents.success,
  warning: colors.accents.warning,

  facebook: colors.brands.facebook,
  messenger: colors.brands.messenger,
  youtube: colors.brands.youtube,
  skype: colors.brands.skype,
  twitter: colors.brands.twitter,

};
export const dark = {};
