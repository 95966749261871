export const cartNameSpace = 'cart';

export const CART_LOADING = 'loading';
export const CART_DATA = 'data';
export const CART_ERRORS = 'error';
export const CART_ERROR_MSG = 'errorMsg';
export const CART_ADD = 'addItemToCart';
export const CART_SUBTOTAL = 'cartSubTotal';
export const CART_COUNT = 'count';
export const CART_SHIPPING_CHARGE = 'shippingCharge';
export const CART_SAVED_ORDER = 'savedOrder';
export const CART_DISCOUNT = 'discount';
export const CART_DISCOUNT_TYPE = 'discountType';
export const CART_DISCOUNT_AMOUNT = 'discountAmount';
export const CART_COUPON_DISCOUNT = 'couponDiscount';
export const CART_COUPON_APPLIED = 'couponApplied';
export const CART_GRAND_TOTAL = 'grandTotal';
export const CART_UPDATE = 'editCartItem';
export const CART_REMOVE = 'removeCartItem';
export const CART_CLEAR = 'cartClear';
