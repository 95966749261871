import { http } from '@/plugins/http';
import {
  FORGOT_PASSWORD_URL,
  LOGGED_IN_USER_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REGISTRATION_URL,
  RESET_PASSWORD_URL
} from '@/utils/api';

import { getCookie } from '@/api/api';
import { getApiResponse } from '@/utils/helper';

export default class Auth {

  static cookieCalled = false;

  static async check() {

    // Check if application already sent cookie request or not
    if (!this.cookieCalled) {
      await getCookie();
      this.cookieCalled = true;
    }

    return getApiResponse(() => http.get(LOGGED_IN_USER_URL));
  }

  static async login(data) {
    return getApiResponse(() => http.post(LOGIN_URL, data));
  }

  static async register(data) {
    return getApiResponse(() => http.post(REGISTRATION_URL, data));
  }


  static async logout() {
    return getApiResponse(() => http.post(LOGOUT_URL));
  }

  static async forgot(email) {
    return getApiResponse(() => http.post(FORGOT_PASSWORD_URL, email));
  }


  static async reset(data) {
    return getApiResponse(() => http.post(RESET_PASSWORD_URL, data));
  }
}
