import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';
import { brands } from '@/api/api';

export default {
  namespaced: true,
  state: () => ({
    ...state,
  }),

  getters: {
    ...getters,
    brandsData: (state) => state?.data || []
  },

  mutations: {
    ...mutations
  },

  actions: {
    ...actions,

    async getBrandData({ dispatch }) {

      const response = await brands();
      dispatch('setData', response.data.data);

    }
  }
};
