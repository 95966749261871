import middlewareHandler from './middlewareHandler';
const { validateUserIsAuthenticated } = middlewareHandler();

async function authenticated(to, from, next) {

  const isAuthenticated = await validateUserIsAuthenticated();

  if(isAuthenticated) {
    return await next();
  }

  return await next({
    name: 'Login',
    query: { redirect: to.fullPath }
  });

}

export default authenticated;
