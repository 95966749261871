import guest from '@/middleware/guest';
import routeMap from '@/utils/routeMap';

const authRoute = [

  {
    path: '/auth',
    component: () => import('@/layout/SlimLayout'),
    children: [
      {
        path: routeMap.auth.login.path,
        name: routeMap.auth.login.name,
        component: () => import('@/views/auth/Login'),
        beforeEnter: guest
      },

      {
        path: routeMap.auth.register.path,
        name: routeMap.auth.register.name,
        component: () => import('@/views/auth/Register'),
        beforeEnter: guest
      },

      {
        path: routeMap.auth.forgot.path,
        name: routeMap.auth.forgot.name,
        component: () => import('@/views/auth/Forgot'),
        beforeEnter: guest
      },

      {
        path: routeMap.auth.reset.path,
        name: routeMap.auth.reset.name,
        component: () => import('@/views/auth/Reset'),
        beforeEnter: guest,
      },
    ]
  }
];

export default authRoute;
